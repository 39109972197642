<template>
  <div class="iss-main contactArrangement">contactArrangement</div>
</template>

<script>
export default {
  name: 'contactArrangement',
};
</script>

<style lang="less" scoped></style>
